import * as React from "react"
import "../../i18n.js";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogBanner from "../components/blog-banner/blogBanner"
import Ticker from "../components/ticker.js";
import { StaticImage } from "gatsby-plugin-image";
// import moduleName from '../images';

const Lab = () => {
  const { t } = useTranslation();

  const products = [
    { title: t("tio-baseTitle"), description: t("tio-baseDescription"), tag: t("saasTag"), link: "/meet-tio" },
    { title: t("cemberTitle"), description: t("cemberDescription"), tag: t("fintechTag"), link: "https://cemberapp.com/" },
    { title: t("hipodocTitle"), description: t("hipoocDescription"), tag: t("saasTag"), link: "https://hipodoc.com/" }
  ]

  const ProductBox = ({ title, description, tag, link }) => (
    <div className="text-blue">
      <div className="font-extrabold flex justify-between mb-6 md:mb-5 lg:mb-7">
        <h3 className="text-5xl md:text-8xl lg:text-9xl">{title}</h3>
        <a href={link} target="_blank" rel="noreferrer" className="flex items-center">
          <svg className="w-10 h-9 md:w-20 md:h-16" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.8636 45.8182L18.5909 40.5909L32.4318 26.75H0V19.0682H32.4318L18.5909 5.25L23.8636 0L46.7727 22.9091L23.8636 45.8182Z" fill="blue" />
          </svg>
        </a>
      </div>
      <p className="text-2xl lg:text-3xl mb-3 md:mb-11 lg:mb-16 w-auto md:max-w-2xl">{description}</p>
      <span className="text-xl">#{tag}</span>
    </div>
  )

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title={t("metaLabTitle")} description={t("metaLabDescription")} canonical="https://tio.ist/lab/" />
      <div className="bg-cream mt-12">
        <p className="text-blue text-center lg:text-xl pb-12">{t("labSubTitle")}</p>
        <Ticker color="blue" text={t("lab")} type="sub" />

        <div className="flex justify-center py-9">
          <svg className="animate-bounce text-center fill-current text-blue" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.35938 -4.76837e-07V12.8438L13.2969 7.89062L14.5156 9.09375L7.5 16.0938L0.5 9.09375L1.6875 7.89062L6.64063 12.8438V-4.76837e-07H8.35938Z" />
          </svg>
        </div>

        <div className="text-center text-blue mx-10 py-20 md:py-28 lg:py-40 mb-12 md:mb-16 lg:mb-24">
          <h2 className="lg:text-2xl">{t("labDescription1")}</h2>
          <p className="text-2xl lg:text-3xl max-w-xl md:max-w-md lg:max-w-xl mx-auto mt-16 lg:mt-20">{t("labDescription2")}</p>
        </div>

        <div className="mx-5 md:ml-32 lg:ml-44 md:mr-24 lg:mr-14 mb-16 md:mb-24 lg:mb-32 space-y-16 md:space-y-24 lg:space-y-32">
          {products.map((product, index) => (
            <ProductBox key={index} title={product.title} description={product.description} tag={product.tag} link={product.link} />
          ))}
        </div>

        <div className="pb-16">
          <div>
            <p className="mx-5 md:mx-32 lg:mx-44 text-6xl md:text-8xl lg:text-9xl font-extrabold text-blue">
              {t("our")}
            </p>
            <div className="overflow-hidden flex text-blue text-6xl md:text-8xl lg:text-9xl font-light italic">
              <div className="flex flex-none items-center min-w-[125%] md:min-w-full animate-scroll-left">
                <p>{t("playground")}</p>
              </div>
              <div className="flex flex-none items-center min-w-[125%] md:min-w-full animate-scroll-left">
                <p>{t("playground")}</p>
              </div>
            </div>

          </div>
          <p className="whitespace-pre-line text-blue text-2xl lg:text-3xl w-64 md:w-128 lg:w-144 mx-5 md:mx-32 lg:mx-44 mt-16 md:mt-20 lg:mt-28">
            {t("playgroundDescription")}
          </p>

          <div className="flex py-16 mx-5 md:mx-0 md:justify-center">
            <div className="w-full md:w-auto flex flex-col py-20 space-y-12 md:space-y-11 lg:space-y-64">
              <a href="https://www.instagram.com/stories/highlights/17921995841413651/" target="_blank" rel="noreferrer" className="group lg:cursor-pointer">
               <div className="block md:flex lg:group-hover:-mb-64">
                <div className="flex md:justify-end w-64 md:w-72 lg:w-96 mb-3 md:mb-0">
                    <figure className="table lg:hidden lg:group-hover:!table">
                      <StaticImage
                        src="../images/ar-filters.png"
                        alt="ar filters"
                      />
                      <figcaption className="table-caption caption-top mb-6">
                        <p className="md:text-sm lg:text-xl text-blue underline font-light">{t("arFiltersInstagramLink")}{" "}↗</p>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="flex justify-end md:justify-start">
                    <h3 className="text-blue italic font-light text-6xl md:text-8xl lg:text-9xl md:ml-5 w-72 md:w-96 lg:w-144">{t("arFilters")}</h3>
                  </div>
               </div>
              </a>
              <a href="https://glossary.tio.ist" target="_blank" rel="noreferrer" className="block md:flex group lg:cursor-pointer lg:!mt-72">
                <div className="flex md:justify-end w-64 md:w-72 lg:w-96 mb-3 md:mb-0">
                  <figure className="table lg:hidden lg:group-hover:!table">
                    <StaticImage
                      src="../images/digital-product-glossary.png"
                      alt="digital product glossary"
                    />
                    <figcaption className="table-caption caption-top mb-6">
                      <p className="md:text-sm lg:text-xl text-blue underline font-light">{t("visitThePage")}{" "}↗</p>
                    </figcaption>
                  </figure>
                </div>
                <div className="flex justify-end md:justify-start">
                  <h3 className="text-blue italic font-light text-6xl md:text-8xl lg:text-9xl md:ml-5 w-72 md:w-96 lg:w-144">{t("digitalProductGlossary")}</h3>
                </div>
              </a>
              <div className="md:flex">
                <div className="flex md:justify-end w-64 md:w-72 lg:w-96 mb-3 md:mb-0">
                  <figure className="table">
                    <StaticImage
                      src="../images/best-of-the-years.png"
                      alt="best of the all years"
                    />
                  </figure>
                </div>
                <div className="flex justify-end md:justify-start">
                  <h3 className="text-blue italic font-light text-6xl md:text-8xl lg:text-9xl md:ml-5 w-72 md:w-96 lg:w-144">{t("bestofAllYears")}</h3>
                </div>
              </div>
              <div className="md:flex">
                <div className="flex md:justify-end w-64 md:w-72 lg:w-96 mb-3 md:mb-0">
                  <figure className="table">
                    <StaticImage
                      src="../images/secret-santa.png"
                      alt="secret santa"
                    />
                  </figure>
                </div>
                <div className="flex justify-end md:justify-start">
                  <h3 className="text-blue italic font-light text-6xl md:text-8xl lg:text-9xl md:ml-5 w-72 md:w-96 lg:w-144">{t("secretSanta")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <BlogBanner />
    </Layout>
  )
}

export default Lab